.ag-root {
  transform: translate3d(0, 0, 0);
  -webkit-transform: translate3d(0, 0, 0);
}

.ag-root .ag-resize-active {
  border-right: 2px solid var(--ag-active-color) !important;
}
.ag-root .ag-resize-pinned-right {
  border-left: 2px solid var(--ag-active-color) !important;
}

.ag-cell {
  border: none !important;
}

.ag-pinned-left-header {
  border-right: none;
}
.ag-pinned-right-header {
  border-left: none;
}
.ag-header {
  height: 32px !important;
  min-height: 32px !important;
}
.ag-header-cell {
  background-color: rgb(250, 251, 252);
  font-family: Roboto, Helvetica, Arial, sans-serif;
  font-size: 0.875rem;
  padding: 0px !important;
  height: 32px !important;
  overflow: visible;
  text-transform: capitalize;
}
.ag-column-first.ag-header-cell {
  padding: 0 15px !important;
}
.ag-sort-indicator-icon {
  scale: 0.7;
}
.ag-header-cell-comp-wrapp {
  height: 32px;
}
.ag-root-wrapper {
  border: none;
}
.ag-cell-label-container {
  height: 40px;
}
.ag-cell .ag-Grid-controlsColumn {
  background-color: rgba(255, 255, 255, 0.9);
  color: rgba(0, 0, 0, 0.87);
  font-size: 0.875rem;
  font-weight: 400;
  font-family: Roboto, Helvetica, Arial, sans-serif;
  border-bottom: 1px solid rgb(224, 224, 224);
}
.ag-overlay-loading-center {
  display: none;
}
.ag-row-position-absolute {
  background-color: rgba(255, 255, 255, 0.3);
}
.ag-row-hover {
  background-color: rgba(250, 251, 252, 0.5) !important;
}

.ag-cell-focus {
  border: none;
}

.ag-ltr .ag-cell-focus:not(.ag-cell-range-selected):focus-within,
.ag-ltr .ag-context-menu-open .ag-cell-focus:not(.ag-cell-range-selected),
.ag-ltr .ag-full-width-row.ag-row-focus:focus .ag-cell-wrapper.ag-row-group,
.ag-ltr .ag-cell-range-single-cell,
.ag-ltr .ag-cell-range-single-cell.ag-cell-range-handle,
.ag-rtl .ag-cell-focus:not(.ag-cell-range-selected):focus-within,
.ag-rtl .ag-context-menu-open .ag-cell-focus:not(.ag-cell-range-selected),
.ag-rtl .ag-full-width-row.ag-row-focus:focus .ag-cell-wrapper.ag-row-group,
.ag-rtl .ag-cell-range-single-cell,
.ag-rtl .ag-cell-range-single-cell.ag-cell-range-handle {
  border: none;
}
.ag-theme-quartz,
.ag-theme-quartz-dark,
.ag-theme-quartz-auto-dark {
  --ag-active-color: #2196f3;
  --ag-background-color: #fff;
  --ag-foreground-color: #181d1f;
  --ag-border-color: color-mix(
    in srgb,
    transparent,
    var(--ag-foreground-color) 15%
  );
  --ag-secondary-border-color: var(--ag-border-color);
  --ag-header-background-color: color-mix(
    in srgb,
    var(--ag-background-color),
    var(--ag-foreground-color) 2%
  );
  --ag-tooltip-background-color: var(--ag-header-background-color);
  --ag-control-panel-background-color: var(--ag-header-background-color);
  --ag-subheader-background-color: transparent;
  --ag-invalid-color: #e02525;
  --ag-checkbox-unchecked-color: color-mix(
    in srgb,
    var(--ag-background-color),
    var(--ag-foreground-color) 30%
  );
  --ag-advanced-filter-join-pill-color: #f08e8d;
  --ag-advanced-filter-column-pill-color: #a6e194;
  --ag-advanced-filter-option-pill-color: #f3c08b;
  --ag-advanced-filter-value-pill-color: #85c0e4;
  --ag-header-column-resize-handle-color: #757575;
  --ag-icon-font-color: color-mix(
    in srgb,
    transparent,
    var(--ag-foreground-color) 90%
  );
  --ag-panel-background-color: color-mix(
    in srgb,
    var(--ag-background-color),
    var(--ag-foreground-color) 3%
  );
  --ag-panel-border-color: color-mix(
    in srgb,
    transparent,
    var(--ag-foreground-color) 20%
  );
  --ag-menu-background-color: color-mix(
    in srgb,
    var(--ag-background-color),
    var(--ag-foreground-color) 3%
  );
  --ag-menu-border-color: color-mix(
    in srgb,
    transparent,
    var(--ag-foreground-color) 20%
  );
  --ag-selected-row-background-color: transparent;
  --ag-row-hover-color: #f5f5f5;
  /*color-mix(in srgb, transparent, var(--ag-active-color) 12%);*/
  --ag-column-hover-color: color-mix(
    in srgb,
    transparent,
    var(--ag-foreground-color) 5%
  );
  --ag-input-focus-border-color: var(--ag-active-color);
  --ag-input-focus-box-shadow: 0 0 0 3px
    color-mix(in srgb, transparent, var(--ag-input-focus-border-color) 47%);
  --ag-range-selection-background-color: color-mix(
    in srgb,
    transparent,
    var(--ag-active-color) 20%
  );
  --ag-range-selection-background-color-2: color-mix(
    in srgb,
    transparent,
    var(--ag-active-color) 36%
  );
  --ag-range-selection-background-color-3: color-mix(
    in srgb,
    transparent,
    var(--ag-active-color) 49%
  );
  --ag-range-selection-background-color-4: color-mix(
    in srgb,
    transparent,
    var(--ag-active-color) 59%
  );
  --ag-checkbox-background-color: var(--ag-background-color);
  --ag-checkbox-checked-color: var(--ag-active-color);
  --ag-range-selection-border-color: var(--ag-active-color);
  --ag-secondary-foreground-color: var(--ag-foreground-color);
  --ag-input-border-color: var(--ag-border-color);
  --ag-input-border-color-invalid: var(--ag-invalid-color);
  --ag-disabled-foreground-color: color-mix(
    in srgb,
    transparent,
    var(--ag-foreground-color) 50%
  );
  --ag-chip-background-color: color-mix(
    in srgb,
    transparent,
    var(--ag-foreground-color) 7%
  );
  --ag-chip-border-color: color-mix(
    in srgb,
    var(--ag-header-background-color),
    var(--ag-foreground-color) 13%
  );
  --ag-input-disabled-border-color: var(--ag-border-color);
  --ag-input-disabled-background-color: color-mix(
    in srgb,
    var(--ag-background-color),
    var(--ag-foreground-color) 6%
  );
  --ag-modal-overlay-background-color: color-mix(
    in srgb,
    transparent,
    var(--ag-background-color) 66%
  );
  --ag-chart-menu-label-color: color-mix(
    in srgb,
    transparent,
    var(--ag-foreground-color) 80%
  );
  --ag-chart-menu-pill-select-button-color: color-mix(
    in srgb,
    transparent,
    var(--ag-foreground-color) 70%
  );
  --ag-borders: solid 1px;
  --ag-border-radius: 0;
  --ag-wrapper-border-radius: 0;
  --ag-borders-side-button: none;
  --ag-side-button-selected-background-color: transparent;
  --ag-header-column-resize-handle-display: block;
  --ag-header-column-resize-handle-width: 2px;
  --ag-header-column-resize-handle-height: 30%;
  --ag-grid-size: 8px;
  --ag-icon-size: 16px;
  --ag-header-height: calc(var(--ag-font-size) + var(--ag-grid-size) * 4.25);
  --ag-row-height: calc(var(--ag-font-size) + var(--ag-grid-size) * 3.5);
  --ag-list-item-height: calc(
    var(--ag-icon-size) + var(--ag-widget-vertical-spacing)
  );
  --ag-column-select-indent-size: var(--ag-icon-size);
  --ag-set-filter-indent-size: var(--ag-icon-size);
  --ag-filter-tool-panel-group-indent: var(--ag-grid-size);
  --ag-advanced-filter-builder-indent-size: calc(
    var(--ag-icon-size) + var(--ag-grid-size) * 2
  );
  --ag-cell-horizontal-padding: calc(var(--ag-grid-size) * 2);
  --ag-cell-widget-spacing: calc(var(--ag-grid-size) * 1.5);
  --ag-widget-container-vertical-padding: calc(var(--ag-grid-size) * 1.5);
  --ag-widget-container-horizontal-padding: calc(var(--ag-grid-size) * 1.5);
  --ag-widget-horizontal-spacing: calc(var(--ag-grid-size) * 1.5);
  --ag-widget-vertical-spacing: calc(var(--ag-grid-size) * 1);
  --ag-toggle-button-height: 18px;
  --ag-toggle-button-width: 28px;
  --ag-toggle-button-border-width: 2px;
  --ag-font-family: 'IBM Plex Sans', -apple-system, BlinkMacSystemFont,
    'Segoe UI', Roboto, Oxygen-Sans, Ubuntu, Cantarell, 'Helvetica Neue',
    sans-serif;
  --ag-font-size: 14px;
  --ag-icon-font-family: agGridQuartz;
  --ag-tab-min-width: 290px;
  --ag-chart-menu-panel-width: 260px;
  --ag-card-shadow: 0 1px 4px 1px rgba(186, 191, 199, 0.4);
  --ag-popup-shadow: 0 0 16px 0 rgba(0, 0, 0, 0.15);
  --ag-side-bar-panel-width: 250px;
}

.ag-header-cell-resize {
  width: 10px;
  cursor: col-resize;
}
.ag-header-cell .ag-header-cell-resize::before {
  content: '';
  position: absolute;
  z-index: 1;
  opacity: 10;
  display: var(--ag-header-column-resize-handle-display);
  width: var(--ag-header-column-resize-handle-width);
  height: var(--ag-header-column-resize-handle-height);
  top: calc(50% - var(--ag-header-column-resize-handle-height) * 0.5);
  background-color: var(--ag-header-column-resize-handle-color);
  right: -3px;
}

.ag-header-cell-resize + .ag-header-cell-resize::after {
  content: '';
  position: absolute;
  z-index: 10;
  opacity: 1;
  display: var(--ag-header-column-resize-handle-display);
  width: var(--ag-header-column-resize-handle-width);
  height: var(--ag-header-column-resize-handle-height);
  top: calc(50% - var(--ag-header-column-resize-handle-height) * 0.5);
  background-color: var(--ag-header-column-resize-handle-color);
  left: -3px;
}
/* .ag-pinned-right-header .ag-header-cell .ag-header-cell-resize::before {
  left: -3px;
}
.ag-pinned-right-header .ag-header-cell-resize + .ag-header-cell-resize::after {
  right: -3px;
} */
.ag-header-cell-resize {
  opacity: 0;
}
.ag-header-cell-resize:hover {
  opacity: 1;
}
.ag-header-cell:hover {
  background: #f0f1f3 !important;
  border-radius: 4px;
}

.ag-header-resizing .ag-header-cell-resize {
  opacity: 1;
}
.ag-header-resizing-pinned-right .ag-header-cell-resize {
  visibility: visible;
  display: block;
}

.ag-header-resizing-pinned-right {
  border-left: 2px solid var(--ag-active-color);
}
.ag-header-resizing {
  border-right: 2px solid var(--ag-active-color);
}

.ag-header-cell {
  border-right: 2px solid transparent;
}

.ag-column-resizing {
  background: #f0f1f3 !important;
  border-radius: 4px 0 0 4px !important;
}

.ag-column-resizing .ag-header-cell-resize {
  opacity: 1;
}

.ag-header-row {
  height: 32px !important;
}

/* Universal webkit scrollbar styling */
*::-webkit-scrollbar {
  width: 7px;
  height: 7px;
  background-color: none;
}

*::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0);
  background-color: #babac080 !important;
}

/* Firefox scrollbar styling */
@supports not selector(::-webkit-scrollbar) {
  * {
    scrollbar-width: thin;
    scrollbar-color: #babac080 transparent;
  }
}

*::-moz-scrollbar {
  width: 7px;
  height: 7px;
  background-color: none;
}

*::-moz-scrollbar-thumb {
  border-radius: 10px;
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0);
  background-color: #babac080 !important;
}

/* AG Grid specific styling */
.ag-theme-quartz,
.ag-theme-quartz-dark {
  --ag-scrollbar-size: 8px !important;

  /* Override any margin/padding that might affect corners */
  .ag-root-wrapper {
    padding: 0 !important;
    margin: 0 !important;
    overflow: hidden !important;
  }

  /* Clean corner handling */
  .ag-body-viewport-wrapper {
    overflow: hidden !important;
  }

  .ag-body-horizontal-scroll-viewport,
  .ag-body-vertical-scroll-viewport {
    background: transparent !important;
  }

  /* Remove all spacers */
  .ag-horizontal-left-spacer,
  .ag-horizontal-right-spacer {
    display: none !important;
    width: 0 !important;
  }
}

/* Override pinned columns corner */
.ag-pinned-right-cols-container,
.ag-pinned-left-cols-container {
  margin-bottom: 0 !important;
}

/* Scroll areas */
.ag-body-horizontal-scroll,
.ag-body-vertical-scroll {
  display: flex !important;
  align-items: flex-end !important;
  justify-content: flex-end !important;
  padding: 0 !important;
  margin: 0 !important;
}

.ag-body-horizontal-scroll {
  height: 10px !important;
  min-height: 10px !important;
}

/* Force clean corners */
.ag-body-viewport::after {
  content: '';
  position: absolute;
  bottom: 0;
  right: 0;
  width: 20px;
  height: 20px;
  background: var(--ag-background-color);
  z-index: 2;
}

/* Force remove scroll buttons and corners */
*::-webkit-scrollbar-button,
*::-webkit-scrollbar-corner,
*::-webkit-resizer {
  display: none !important;
  width: 0 !important;
  height: 0 !important;
  background: transparent !important;
}

/* Hover effects */
.ag-body-horizontal-scroll:hover *::-webkit-scrollbar,
.ag-body-vertical-scroll:hover *::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

.ag-body-horizontal-scroll *::-webkit-scrollbar-thumb:hover,
.ag-body-vertical-scroll *::-webkit-scrollbar-thumb:hover {
  background-color: #babac0b3 !important;
}

.ag-root .ag-header-cell.ag-column-moving,
.ag-root .ag-cell.ag-column-moving {
  transition: none !important;
}

.ag-root .ag-header-cell.ag-column-resizing,
.ag-root .ag-cell.ag-column-resizing {
  transition: none !important;
}

.ag-header-container {
  min-width: 100% !important;
  width: 100% !important;
}

.ag-root .ag-checkbox-input-wrapper input:focus {
  box-shadow: none !important;
  outline: none !important;
}

.ag-root .ag-checkbox-input-wrapper:focus-within {
  box-shadow: none !important;
  outline: none !important;
}

.ag-pinned-left-header,
.ag-pinned-left-cols-container {
  box-shadow: 16px 0 16px -6px rgba(24, 39, 75, 0.05);
  z-index: 10;
  position: relative;
}

/* Right pinned column shadows */
.ag-pinned-right-header,
.ag-pinned-right-cols-container {
  box-shadow: -16px 0 16px -6px rgba(24, 39, 75, 0.05);
  z-index: 10;
  position: relative;
}

.ag-root .row-actions-menu .actions-menu-wrapper {
  border: none;
}

.ag-root .ag-row-hover .row-actions-menu .actions-menu-wrapper,
.ag-root.row-actions-menu .actions-menu-wrapper.open {
  border: 1px solid lightGrey;
}

.ag-root .row-actions-menu .actions-menu-wrapper .quick-actions {
  opacity: 0;
  width: 0;
  transition: all 0.2s ease-in-out;
}

.ag-root .ag-row-hover .row-actions-menu .actions-menu-wrapper .quick-actions,
.ag-root.row-actions-menu .actions-menu-wrapper.open .quick-actions {
  opacity: 1;
  width: auto;
  padding-right: 20px;
}

.ag-root .row-actions-menu .actions-menu-wrapper .menu-trigger-icon {
  transition: all 0.2s ease-in-out;
}

.ag-root
  .ag-row-hover
  .row-actions-menu
  .actions-menu-wrapper
  .menu-trigger-icon,
.ag-root
  .ag-row-hover
  .row-actions-menu
  .actions-menu-wrapper.open
  .menu-trigger-icon {
  right: 4px;
  position: absolute;
}

.ag-overlay {
  z-index: 20;
}

.ag-cell[col-id='ag-Grid-ControlsColumn'] {
  display: flex;
  align-items: center;
}
